.dropdownFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.checkboxDropdownBox {
    display: none;
}

.blockButton {
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #a0aebf;
    width: 106px;
    height: 61px;
    
}

.blockButton.checked {
    border: 2px solid #00c2bd;
    color: #00c2bd;

}

.blockButton > .checkedIcon {
    position:absolute;
    top: -8px;
    left: 95px;
    background-color: #fff;

}

.filterFlexContainer {
    display: flex;
    flex-wrap: wrap;
}
.filterFlexItem {
    flex: 1 0 25%;
}
.filterMultiselectCheckbox > div,
.filterMultiselectCheckbox > div > button {
    height: 100%;
}
.noPadding {
    padding: 0px !important;
}
.spaceRight {
    margin-right: 30px;
}

.dropdownListBody {
    position:relative;
    height: calc(510px - 70px);
    overflow-y: auto;
}
.checkBoxLine {
    margin-bottom: 10px;
}

.checkBoxLine .disabled {
    font-weight: 600;
}

.checkBoxLine .hide {
    display: none;
}

.checkBoxLine input[type="checkbox"]:disabled {
    
    display: none;
}
.hideCheckBoxLine {
    display: none;
}

.messageMultiStepsSummary {
    li {
        list-style: none;
    }
    .badge {
        font-size: 13px;
        font-weight: 500;
    }

    .routeBadge {
        color: #25252c;
        background-color: #e5eaf3;
    }

    .stopBadge {
        color: #25252c;
        background-color: #e5eaf3;
    }
}
